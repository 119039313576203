.c-tiles{
	$_this: &;

	&__group{
		@include mq-down(xs){
			padding-bottom: 0 !important;
			&:not(:first-child){
				margin-top: calc(#{percentage(tan($theme-angle) / -1)}) !important;
			}
		}
	}

	&__media{
		padding-bottom: 0 !important;

		&-item{
			transition: .3s;
			transform: scale(1.33) skewY($theme-angle);

			@include mq(xl){
				transform: scale(1.3) skewY($theme-angle);
			}

			.c-testimonial &{
				@include mq(md){
					// transform: scale(1.24) skewY($theme-angle);
				}
			}


			#{$_this}__item:hover &{
				transform: scale(1.4) skewY($theme-angle);
			}
		}
	}

	&__mediacontainer{
		transform: skewY(-$theme-angle);
	}

	&__item{
		height: 260px;

		@include mq(sm){
			height: 256px;
		}

		@include mq(md){
			height: 336px;
		}

		@include mq(lg){
			height: 412px;
		}
		@include mq(2000px){
			height: 520px;
		}

		@include mq-down(xs){
			top: auto !important;

			&:not(:first-child){
				margin-top: calc(#{percentage(tan($theme-angle) / -1)}) !important;
			}
		}
	}
}
