.c-newslistingitem{
	@include mq(md){
		border-bottom: 0;
	}
	&__mediacol{
		flex-basis: 112px;
		max-width: 112px;
		@include mq(sm){
			flex-basis: 180px;
			max-width: 180px;
		}
		@include mq(md){
			flex-basis: auto;
			max-width: 100%;
		}
	}
}
