.c-nav-mobile{

	&__inner{
		/* crit:start */
		transform: translateX(100%);
		/* crit:end */
		transition: .3s;

		@include mq(sm){
			width: 360px;
		}
		.is-menu-active &{
			transform: none;
		}
	}

	&__overlay{
		transition: opacity .2s, z-index .1s .1s;

		.is-menu-active &{
			transition: z-index .1s, opacity .2s .1s;
			opacity: 1;
			z-index: 5;
		}
	}

	&__item{
		@include fzlh(25px, 36px);
		font-family: RockebyCd;
		letter-spacing: 1px;
		text-transform: uppercase;
		color: $tertiary6;
	}

	&__childmenubtn{
		width: 53px;
	}
}
