.c-header {
    flex-shrink: 0;
    @each $breakpoint in map-keys($grid-breakpoints) {
        $header-height: map-get($header-heights, $breakpoint);
        @if ($header-height !=null) {
            @include mq-up($breakpoint) {
                height: $header-height;
                &__inner {
                    height: $header-height;
                }
            }
        }
    }
    &__logo {
        &-img {
            width: 192px;
            transition: width 0.5s cubic-bezier(0.71, -0.01, 0.42, 0.99);
            @include mq(sm) {
                width: 228px;
            }
            @include mq(xl) {
                width: 275px;
                transition: width 0.75s cubic-bezier(0.56, -0.01, 0.32, 1.01);
            }
            .has-transition & {
                width: 192px;
            }
        }
        .has-transition & {
            height: 64px;
        }
    }
    &__mobiletoggle {
        width: 32px;
        height: 32px;
        &--main {
            width: 48px;
            height: 48px;
        }
    }
    &__inner {
        position: fixed !important;
        transition: background-color 0.3s, transform 0.25s ease-in, height 0.5s;
        &.has-transition {}
        &.is-top {}
        &.is-not-top {}
        &.is-unpinned {
            transform: translateY(-100%);
        }
        &.is-pinned.is-not-top {
            height: 88px;
        }
        &-bgc {
            background-color: $tertiary5;
            opacity: 0;
            transition: opacity .3s linear;
		}

		.is-book-page & {
			@include mq(lg) {
				// z-index: 6;
			}
		}
    }
    &__container {
        position: relative;
        z-index: 1;
        @include mq(xl) {
            max-width: 100%;
        }
        .has-transition.is-not-top & {
            padding-top: 16px;
            padding-bottom: 16px;
        }
        &-inner {
            .has-transition.is-not-top & {
                align-items: center !important;
            }
        }
	}
	&__bookpagecurrencyselector {
		.is-book-page &{
			@include mq(sm) {
				display: flex;
			}
		}
	}
}

.is-book-page {
    max-height: 100dvh;
    overflow: hidden;
    @include mq-down(md) {
        overflow: auto;
        max-height: none;
    }
    .hide-booking-only {
        display: none;
    }
    .c-header__mobiletoggle {
        @include mq-down(lg) {
            display: none;
        }
    }
}

.show-booking-only {
    display: none;
    .is-book-page & {
        display: block;
    }
}