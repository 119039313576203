/* crit:start */

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

// Mixin are from _tools.mixin.fontstyles.scss
h1 {
    @include h3;
    @include mq(sm) {
        @include h2;
    }
    @include mq(lg) {
        @include h1;
    }
}

h2 {
    @include h4;
}

h3 {
    @include h5;
}

h4 {
    @include h6;
}

h5 {
    @include h5;
}

h6 {
    @include h6;
}

p {
    &:empty {
        display: none;
    }
}

label {
    margin-bottom: 0;
}

label,
.label {
    @include fslabel;
}

figcaption,
.caption {
    @include fscaption;
}

// blockquote{
// 	@include fsblockquote;
// 	quotes: "�" "�" "�" "�";
// 	padding-top: 62px;
// 	position: relative;
// 	color: #505050;
// 	@include mq(md){
// 		padding-left: 18px;
// 	}
// 	&:before{
// 		@include icon(quote);
// 		color: rgba(#000, .2);
// 		font-size: 40px;
// 		max-height: 40px;
// 		line-height: 1;
// 		position: absolute;
// 		top: 0;
// 		left: 0;
// 	}
// }
.lead {
    @include fslead;
    @include mq(sm) {
        @include fsleadlarge
    }
}

.b-fsoverline {
    @include fsoverline;
}

.b-fsbtn {
    @include fsbtn;
}

.b-fsbtnlarge {
    @include fsbtnlarge;
}

.b-fsnavprimary {
    @include fsnavprimary;
}

.b-fsnavsecondary {
    @include fsnavsecondary;
}

.b-fsformxsmall {
    @include fsformxsmall
}

.b-fsformlarge {
    @include fsformlarge
}

.b-fsbodyregularsmall {
    @include fsbodyregularsmall
}

.b-fsdisclaimer {
    @include fsdisclaimer
}

.b-fssubtitle {
    @include fssubtitle
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include mq-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
        .h1#{$infix} {
            @include h1;
        }
        .h2#{$infix} {
            @include h2;
        }
        .h3#{$infix} {
            @include h3;
        }
        .h4#{$infix} {
            @include h4;
        }
        .h5#{$infix} {
            @include h5;
        }
        .h6#{$infix} {
            @include h6;
        }
        .b-fsbodyregular#{$infix} {
            @include fsbodyregular;
        }
        .b-fsinputsmall#{$infix} {
            @include fsinputsmall;
        }
    }
}

.b-cursive {
    @include fzlh(24px, 32px);
    font-family: $font-family-cursive;
}
