.c-compare{
	&__overline{
		padding-top: 10px;
		padding-bottom: 10px;
	}

	&__slider{
		&-slide{
			&--popular{
				box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
			}
		}
	}
}
