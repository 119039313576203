// Default Variables
// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"
$slick-font-path: "./fonts/";
$slick-font-family: "g-icons";
$slick-loader-path: "./";
$slick-arrow-color: white;
$slick-dot-color: black;
$slick-dot-color-active: $slick-dot-color;
$slick-prev-character: "";
$slick-next-character: "";
$slick-dot-character: "";
$slick-dot-size: 6px;
$slick-opacity-default: 1;
$slick-opacity-on-hover: .75;
$slick-opacity-not-active: 0.2;

/* crit:start */

@import '../../node_modules/slick-carousel/slick/slick.scss';
@import '../../node_modules/slick-carousel/slick/slick-theme.scss';

/* crit:end */

// ==========================================================================
// Override so that don't need to use assets from slick
// ==========================================================================
.slick {
    $_this: &;
    // {{ .slick-slider }}
    &-slider {
        @include mq(md) {
            user-select: initial;
        }
        &.slick-dotted {
            margin-bottom: 0;
            .c-compare__slider & {
                margin-bottom: 64px;
            }
        }
    }
    // {{ .slick-slide }}
    &-slide {
        height: auto;
        flex: 1;
        outline: 0;
        /* crit */
        .lt-ie10 {
            height: 100%;
            /* crit */
        }
        .c-slider__navslider & {
            opacity: .5;
            padding: 4px 2px;
            position: relative;
            width: 90px !important;
            height: 72px;
            &:before {
                content: '';
                z-index: -1;
                position: absolute -2px;
                background-color: $primary;
                opacity: 0;
            }
            &.slick-current {
                opacity: 1;
                &:before {
                    opacity: 1;
                }
            }
        }
        .c-testimonial & {
            height: 100%;
            // display: flex;
        }
    }
    &-list {
        .c-compare__slider & {
            @include mq-between(sm, md) {
                &:before,
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 40px; //padding top for the "Most popular"
                    bottom: 0;
                    z-index: 1;
                }
                &:before {
                    left: 0;
                    border-left: 1px solid $border;
                }
                &:after {
                    right: 0;
                    border-right: 1px solid $border;
                }
            }
        }
    }
    // {{ .slick-track }}
    &-track {
        display: flex;
        /* crit */
        &:before {
            content: none;
            /* crit */
        }
        .lt-ie10 & {
            &:before {
                content: '';
                /* crit */
            }
        }
        .c-testimonial & {
            height: 100%;
        }
        .c-slider__navslider & {
            display: block;
        }
        .c-compare__slider & {
            &:before,
            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 40px; //padding top for the "Most popular"
                bottom: 0;
            }
            &:before {
                left: 0;
                border-left: 1px solid $border;
            }
            &:after {
                right: 0;
                border-right: 1px solid $border;
            }
        }
    }
    &-dots {
        top: auto;
        bottom: 16px;
        .c-testimonial & {
            bottom: 40px;
            @include mq(sm) {
                bottom: 80px;
            }
            @include mq(lg) {
                bottom: 108px;
            }
            @include mq(xl) {
                bottom: 132px;
            }
        }
        .c-module--homesocialslider & {
            z-index: 1;
        }
        .c-compare__slider & {
            bottom: -46px;
		}
		.c-specialeventslides &{
			top: calc(100% + 12px);
			bottom: auto;
		}

        li {
            width: 20px;
            height: 20px;
            margin: 0;
            button {
                color: #fff;
                width: 20px;
                height: 20px;
                padding: 0;
                &:before {
                    content: '';
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 8px;
                    height: 8px;
                    background-color: #fff;
                    line-height: 1;
                    border-radius: 50%;
                    .c-compare__slider & {
                        background-color: $border;
                        opacity: 1;
                    }
                    .c-testimonial__modal & {
                        background-color: $tertiary6;
					}
					.c-specialeventslides &{
						background-color: $tertiary6;
					}
                }
            }
            &.slick-active {
                button {
                    &:before {
                        .c-compare__slider & {
                            background-color: $tertiary6;
                        }
                    }
                }
            }
            .c-module--testimonial & {
                z-index: 3;
            }
        }
    }
    // {{ .slick-list }}
    &-list {
        #{$_this}-loading & {
            background: #fff 50% no-repeat;
            /* crit */
        }
        .c-testimonial & {
            height: 100%;
        }
    }
    // {{ .slick-arrow }}
    &-arrow {
        z-index: 1;
        width: 32px;
        height: 32px;
        &:before {
            display: block;
            height: 100%;
            background-size: auto 32px;
            background-repeat: no-repeat;
        }
        .c-compare__slider & {
            width: 40px;
            height: 56px;
            background-color: $tertiary6;
            &:before {
                background-size: 24px;
                background-position: center;
            }
            &.slick-disabled {
                display: none !important;
            }
        }
    }
    // {{ .slick-prev }}
    &-prev {
        left: 16px;
        &:before {
            background-image: url(/www_shared/assets/img/chevron-left.svg)
		}
		.c-compare__slider & {
            left: -40px;
            border-radius: 2px 0px 0px 2px;
            &:before {
                background-image: url(/www_shared/assets/img/chevron-left.svg)
            }
        }
		.c-specialeventslides & {
            left: -(32px + 24px); //32 is the width of the icon + the gap 24px
            &:before {
                background-image: url(/www_shared/assets/img/chevron-left32-black.svg)
            }
        }
    }
    // {{ .slick-next }}
    &-next {
        right: 16px;
        &:before {
			background-image: url(/www_shared/assets/img/chevron-right.svg)
        }
		.c-compare__slider & {
			right: -40px;
			border-radius: 0px 2px 2px 0px;
			&:before {
				background-image: url(/www_shared/assets/img/chevron-right.svg)
			}
		}
		.c-specialeventslides & {
			right: -(32px + 24px); //32 is the width of the icon + the gap 24px
			&:before {
				background-image: url(/www_shared/assets/img/chevron-right32-black.svg)
			}
		}
    }
}
