.c-currencymodal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100dvh;
    background-color: rgba(255, 255, 255, 0.65);
    justify-content: center;
    align-items: center;
    z-index: 900;
    color: $white;
    padding: 0 1rem;
    @include mq-down(sm){
        align-items: flex-start;
        padding: 1rem;
        padding-top: 4rem;
    }
    &.is-active {
        display: flex;
    }
    &__backdrop {
        position: absolute;
        width: 100%;
        height: 100%;
    }
    &__container {
        max-width: 640px;
        background-color: #4A818D;
        padding-top: 72px;
        position: relative;
        z-index: 2;
    }
    &__close {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        border: 0;
        font-family: RockebyCd;
        background-color: $primary;
        color: $white;
        padding: 0 8px;
    }
    &__image {
        @include mq-down(sm){
			max-width: 120px;
		}
    }
    &__heading {
        @include mq-down(sm){
			font-size: 2.4rem;
		}
    }
    &__select {
        position: relative;
        margin: 0.75rem 0;
        &__dropdown {
            top: 100%;
            bottom: auto;
            z-index: 10;
            display: none;
            max-height: 360px;
            overflow: auto;
            @include mq-down(sm){
                max-height: 240px;
            }
        }
        &.is-open {
            .c-currencymodal__select__dropdown {
                opacity: 1;
                display: block;
            }
        }
    }
    &__selector {
        display: inline-flex;
        align-items: stretch;
        &__text {
            display: inline-block;
            color: $primary;
            background-color: $white;
            padding-left: 8px;
            padding-right: 8px;
        }
        &__indicator {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: $primary;
            width: 32px;
            &:before {
                content: '';
                display: block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 12px 10px 0 10px;
                border-color: #ffffff transparent transparent transparent;
            }
        }
    }
}