.c-specialeventslides{
	&__slide {
		.slick-initialized &{
			display: flex;
		}
	}
	&__tile{
		min-height: 340px;

		&-media {
			padding-bottom: 50%;
			@include mq(sm) {
				padding-bottom: percentage(340/660);
			}
			@include mq(md) {
				flex-basis: 50%;
				height: auto;
				padding-bottom: 0;
			}
		}

		&-text{
			@include mq(md) {
				flex-basis: 50%;
			}
		}

		&-verticalangle{
			transform: skew(-12deg);
			transform-origin: left top;
		}
	}
}
