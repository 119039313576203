.o-inputbox{
	position: relative;
	$_this: &;

	&__tick{
		&--circle{
			#{$_this}--radio &{
				display: block;
			}
		}
		&:not(&--circle){
			#{$_this}--radio &{
				display: none;
			}
		}
	}

	&__box{
		width: 20px;
		height: 20px;
		border: 1px solid $tertiary2;
		top: 3px;
		background: $white;
		/* crit */

		#{$_this}--radio &{
			border-radius: 50%;
			/* crit */
		}

		input:focus ~ & {
			border-color: $secondary2;
			box-shadow: 0 0 0 1px $tertiary6;
		}

		input:checked ~ &, .is-selected &{
			border-color: $secondary2;
			background: $secondary2;
			/* crit */

			#{$_this}__tick{
				opacity: 1;
				/* crit */
			}

			input:disabled~& {
				opacity: .5;
				/* crit */
			}
		}

		input:disabled~& {
			opacity: .5;
			/* crit */
		}
	}
	&__text{
		input:disabled~& {
			opacity: .5;
		}

		&--dark {
			color: $tertiary1;

			a {
				color: $tertiary3;
				text-decoration: underline;
			}
		}
	}

	.error-jqvalidate {
		position: absolute;
		bottom: 5px;
	}
}
