.o-giftcardtile{
	$_this: &;

	transition: .4s;
	box-shadow: 0px -5px 20px rgba(0, 0, 0, 0.2);

	&.is-active {
		#{$_this}__details__toggle__plus {
			display: none;
		}
		#{$_this}__details__toggle__minus {
			display: inline-block;
		}
	}

	&__price{
		border-color: rgba(#fff, .4);
	}

	&__expand{
		// box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);

		@include mq-down(xs){
			transition: .5s;
			#{$_this}__expander:not(.collapsed) ~ &{
				margin-left: -$grid-gutter-width / 2;
				margin-right: -$grid-gutter-width / 2;
				margin-bottom: $grid-gutter-width;
			}
		}

	}

	&__tile{

		overflow: hidden;
		
		@include mq-down(xs){
			transition: .5s;
			#{$_this}__expander:not(.collapsed) ~ &{
				margin-left: -$grid-gutter-width / 2;
				margin-right: -$grid-gutter-width / 2;
			}
		}

		&shadow{

			#{$_this}__expander.collapsed ~ #{$_this}__tile &{
				opacity: 0;
			}
		}

		.caption {
			font-size: .5em;
		}
	}

	&__expandindicator{
		&--collapsed{
			#{$_this}__expander.collapsed ~ #{$_this}__tile &{
				display: block;
			}
		}
		&--expanded{
			#{$_this}__expander.collapsed ~ #{$_this}__tile &{
				display: none;
			}
		}
	}
	&__boxshadow {
		box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
	}
	&__details {
		position: relative;
		&.is-active {
			#{$_this}__details__overlay {
				display: flex;
			}
		}
		&__toggle {
			z-index: 2;
			position: relative;
			overflow: hidden;
			padding: 0 1rem;
			&__plus {
				font-size: 28px;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}
			&__minus {
				display: none;
				font-size: 28px;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}
		}
		&__overlay {
			display: none;
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 10;
			justify-content: center;
			align-items: center;
			&__bg {
				width: 100%;
				height: 100%;
				position: fixed;
				top: 0;
				left: 0;
				background-color: rgba(19, 21, 24, 0.45);
			}
		}
	}
	&__modal {
		max-width: 620px;
		width: 100%;
		position: relative;
		z-index: 4;
	}
	&__description {
		transition: transform 0.4s ease-in-out;
		overflow: auto;
		.truncate-text {
			overflow: hidden;
			display: -webkit-box;
			line-clamp: 3;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
		}
	}
	&__see-more-btn {
		appearance: none;
		border: 0;
		margin: 0;
		padding: 0;
		background: transparent;
		font-size: 1rem;
		margin-top: 0.5rem;
		display: none;
    	align-items: center;
		svg {
			margin-left: 0.5rem;
		}
		&.show {
			display: flex;
		}
	}
}
