.o-icon{
	width: 24px;
	height: 24px;

	&--xs{
		width: 12px;
		height: 12px;
	}

	&--s{
		width: 16px;
		height: 16px;
	}

	&--l{
		width: 32px;
		height: 32px;
	}

	&--xl{
		width: 36px;
		height: 36px;
	}
}
