.c-climb {
    &__path {
        margin-top: -3 * $baseline;
        @include mq(md) {
            margin-top: -4 * $baseline;
        }
    }
    &__book {
        &-btncontainer {
            // for some reason, the scrollmagic adds z-index: 0 on iphone xs
            z-index: 7 !important;
            @include mq(xl) {
                right: 48px;
                top: 16px;
                left: auto;
                bottom: auto;
                opacity: 0;
                &#{&}#{&} {
                    transform: translateX(100%) !important;
                }
            }
            .is-menu-active & {
                display: none;
            }
            &.has-transition {
                transition: .5s;
                @include mq(xl) {
                    transition: .3s .3s;
                    &.is-headroom-connect {
                        opacity: 1;
                    }
                    &#{&} {
                        transform: none !important;
                    }
                    &.is-down {
                        transition: .3s;
                    }
                }
            }
            &.is-down {
                transform: translateY(100%) !important;
                @include mq(xl) {
                    opacity: 0 !important;
                    &#{&} {
                        transform: translateX(100%) !important;
                    }
                }
            }
        }
    }
    &__hero {
        &-prices {
            &-item {
                &:not(:last-child) {
                    border-right: 1px solid rgba(#fff, .2);
				}

				&-price{
					.caption {
						display: block;
					}
				}
            }
        }
    }
}
